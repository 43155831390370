import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import { useEffect, startTransition, StrictMode } from "react"
import { hydrateRoot } from "react-dom/client"
import * as Sentry from "@sentry/remix"

Sentry.init({
  dsn: "https://06654f44c946478bba5d164064e03725:860da6cb69ca406583f08f678e92b0d8@o471.ingest.sentry.io/4505077945466880",
  release: "SENTRY-RELEASE-PLACEHOLDER",
  includeLocalVariables: true,
  tracesSampleRate: 0,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
